<template>
    <div class="main">
        <Breadcrumb :breadcrumb="breadcrumbItems"/>
        <div class="row">
            <div class="col-12">
                <List/>
            </div>
        </div>
        <MetaTags 
            title="トークールームリスト｜ResMom相談online"
            description="リセマム相談オンラインのトークルームリストです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>
<script>
import List from './components/list.vue';
export default {
    name: "TalkRoom",
    data() {
        return {
            breadcrumbItems: [
                {
                    title: this.$t("menu.breadcrumb.my_page"),
                    link: "/my-page"
                },
                {
                    title: this.$t("menu.breadcrumb.talk_room_list"),
                    link: "#"
                }
            ]
        }
    },
    components:{
        List
    },
}
</script>